import get from 'lodash/get';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { Col, Row } from 'react-styled-flexboxgrid';
import styled from 'styled-components';

import { APIConfiguration } from '@savgroup-front-common/configuration';
import { H3 } from '@savgroup-front-common/core/src/atoms/headings';
import { AddressConfirmation } from '@savgroup-front-common/core/src/components/addressConfirmation';
import { Selectors as ClaimsSelectors } from '@savgroup-front-common/core/src/domains/claims';
import { summaryFileProductsSelector } from 'myaccount/domains/Views/fileSummary';
import {
  currentProductInfoSelector,
  handlingSummary as handlingSummarySelector,
} from 'myaccount/domains/Views/handlingSummary';
import { TransportDeduction } from 'myaccount/view/components';
import ProductIRSSummaryCardsGroup from 'myaccount/view/components/ProductIRSSummaryCardsGroup';

import { customerState } from '../../../../../domains/Customer/selectors';

import messages from './messages';

const RowSectionWrapper = styled(Row)`
  margin-bottom: 16px;
`;

const InfoTitleH3 = styled(H3)`
  font-weight: ${({ theme }) => theme.fonts.weight.mediumFont};
  font-size: 16px;
`;

const Summary = () => {
  const customer = useSelector(customerState);
  const productInfo = useSelector(currentProductInfoSelector);
  const handlingSummary = useSelector(handlingSummarySelector);
  const products = useSelector(summaryFileProductsSelector);

  const fileSummary = customer.customerFileSummary;

  const transportDepositSummary = get(fileSummary, 'transportDepositSummary');
  const transportDeliverySummary = get(fileSummary, 'transportDeliverySummary');
  const confirmationDatas = get(productInfo, [0]);
  const { solutionPriceWithTax, solutionPriceCurrency } =
    confirmationDatas || {};
  const paymentBehavior = get(fileSummary, 'paymentBehavior');

  const claimGroupId = get(customer, [
    'customerFileSummary',
    'claimInfoSummary',
    'claimGroupId',
  ]);

  const confirmationClaimGroup = useSelector((state) =>
    ClaimsSelectors.getClaimGroupsConfirmationValue(state, claimGroupId),
  );
  const confirmationClaimGroupValue = get(confirmationClaimGroup, 'value');
  const deposit = {
    ...handlingSummary.deposit,
    carrierBrand: get(confirmationClaimGroupValue, 'carrierDepositBrandName'),
    price: get(confirmationClaimGroupValue, 'carrierDepositPriceWithTax'),
  };
  const delivery = handlingSummary.delivery
    ? {
        ...handlingSummary.delivery,
        carrierBrand: get(
          confirmationClaimGroupValue,
          'carrierDeliveryBrandName',
        ),
        price: get(confirmationClaimGroupValue, 'carrierDeliveryPriceWithTax'),
      }
    : null;

  return (
    <>
      <ProductIRSSummaryCardsGroup products={products} />
      <RowSectionWrapper>
        {deposit && get(deposit, 'address') ? (
          <Col xs={12} md>
            <Row>
              <Col xs={12}>
                <Row middle="xs">
                  <Col>
                    <InfoTitleH3 heading>
                      <FormattedMessage {...messages.deposit} />
                    </InfoTitleH3>
                  </Col>
                </Row>
              </Col>
              <Col xs={12}>
                <AddressConfirmation
                  {...deposit}
                  imagePath={`${APIConfiguration.catalogCDN}carriers/${(
                    deposit.carrierBrand || 'external'
                  ).toLowerCase()}.png`}
                  transportMethod={
                    transportDepositSummary
                      ? get(transportDepositSummary, 'name')
                      : null
                  }
                  price={get(deposit, 'price')}
                  showPrice
                />
              </Col>
            </Row>
          </Col>
        ) : null}
        {delivery && get(delivery, 'address') ? (
          <Col xs={12} md>
            <Row>
              <Col xs={12}>
                <Row middle="xs">
                  <Col>
                    <InfoTitleH3 heading>
                      <FormattedMessage {...messages.delivery} />
                    </InfoTitleH3>
                  </Col>
                </Row>
              </Col>
              <Col xs={12}>
                <AddressConfirmation
                  {...delivery}
                  imagePath={`${APIConfiguration.catalogCDN}carriers/${(
                    delivery.carrierBrand || 'external'
                  ).toLowerCase()}.png`}
                  transportMethod={
                    transportDeliverySummary
                      ? get(transportDeliverySummary, 'name')
                      : null
                  }
                  price={get(delivery, 'price')}
                  showPrice
                />
              </Col>
            </Row>
          </Col>
        ) : null}
      </RowSectionWrapper>
      {paymentBehavior === 'PaymentDeductedFromRefund' ? (
        <TransportDeduction
          transportDeduction={solutionPriceWithTax}
          currency={solutionPriceCurrency}
        />
      ) : null}
    </>
  );
};

Summary.displayName = 'Summary';

export default Summary;
