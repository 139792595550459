import { useMutation } from 'react-query';

import { logError } from '@savgroup-front-common/configuration/src/appInsights/AppInsights';
import { PaymentService } from 'myaccount/api';

export const useConfirmPaymentIntent = ({
  onSuccess,
  invoiceId,
  claimGroupId,
  fileId,
}: {
  invoiceId: string;
  onSuccess: () => void;
  claimGroupId: string;
  fileId: string;
}) => {
  const { mutateAsync } = useMutation(
    'confirmPaymentIntent',
    async () => {
      const response = await PaymentService.confirmPaymentIntent({ invoiceId });

      if (response.failure) {
        throw new Error(response.errors[0].message);
      }

      onSuccess();
    },
    {
      retry: 5,
      retryDelay: 2_500,
      onError: (error) => {
        logError(
          `InvoicePaymentForm.handleSubmit: invoiceId => ${invoiceId}, claimGroupId => ${claimGroupId}, fileId => ${fileId}`,
          { invoiceId, error },
        );
      },
    },
  );

  return { handleConfirmPaymentIntent: mutateAsync };
};
