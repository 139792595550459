import { useQuery } from 'react-query';

import { PaymentService } from 'myaccount/api';

const useCreatePaymentIntent = ({ invoiceId, handleConfirmPaymentIntent }) => {
  const { data: paymentIntentClientSecret } = useQuery(
    ['CreatePaymentIntent', { invoiceId }],
    async () => {
      if (!invoiceId) {
        return undefined;
      }

      const response = await PaymentService.createPaymentIntent({ invoiceId });

      if (response.failure) {
        if (
          response.errors.some(
            (error) =>
              error.code === 'Reverse.Payment.Errors.Payment.CannotPaidTwice',
          )
        ) {
          await handleConfirmPaymentIntent();
        }

        return undefined;
      }

      return response.value;
    },
    {
      staleTime: Infinity,
    },
  );

  return paymentIntentClientSecret;
};

export default useCreatePaymentIntent;
