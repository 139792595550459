import find from 'lodash/find';
import get from 'lodash/get';
import head from 'lodash/head';
import map from 'lodash/map';
import size from 'lodash/size';
import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { Col, Grid, Row } from 'react-styled-flexboxgrid';
import { useMedia } from 'react-use';
import { replace } from 'redux-first-history';

import { media, SPECIFIC_ERROR_CODES } from '@savgroup-front-common/constants';
import { NeedHelp } from '@savgroup-front-common/core/src/components/NeedHelp';
import { getFromDataByKey } from '@savgroup-front-common/core/src/helpers';
import {
  clearClaimConfirmationErrors,
  confirmClaimGroup,
} from '@savgroup-front-common/core/src/domains/claims/actionCreators';
import { useSelectActionError } from '@savgroup-front-common/core/src/domains/sagaRequestMetadata';
import { AuthConfiguration } from 'myaccount/configuration';
import { getIssuesPageLink } from 'myaccount/domains/ClaimGroupEdition/saga';
import { createInvoiceForClaimGroup } from 'myaccount/domains/payments/actionCreators';

import OldPageHeader from '../../../components/OldPageHeader/OldPageHeader';

import { $Wrapper } from './ClaimGroupConfirmation.styles';
import Confirmation from './Confirmation/Confirmation';

const ClaimGroupConfirmation = ({
  fileProducts,
  assets,
  login,
  confirmationIsLoaded,
  confirmation,
  translatedCarrirerMethodNames,
  currentInvoice,
  currentConfirmationLoadingStatus,
  currentConfirmationErrors,
  getClaimHandlingLink,
  fileIsLoading,
  productsFullInfo,
  storeActorOptions,
}) => {
  const dispatch = useDispatch();
  const isGridFluidActive = useMedia(media.maxWidth.md);

  const claims = get(confirmation, 'claims', []);
  const claimIds = claims.map(({ claimId }) => claimId);

  const products = map(fileProducts, (product) => {
    const currentClaim =
      find(claims, (claim) => claim.claimId === product.claimId) || {};

    return {
      ...product,
      ...currentClaim,
      ...get(product, ['productInfo'], {}),
      issue: get(currentClaim, ['issueName']),
      reason: get(currentClaim, ['reasonName']),
      solution: get(currentClaim, ['solutionTypeName']),
      documents: get(currentClaim, ['documents']),
      price: get(currentClaim, ['solutionPrice']),
      solutionGroupId: get(currentClaim, ['solutionGroupId']),
      comment: get(
        find(get(currentClaim, 'comments'), { type: 'ReasonComment' }),
        'content',
      ),
    };
  });

  const sellerId = get(productsFullInfo(get(head(products), 'productId')), [
    'value',
    'sellerId',
  ]);

  const dispatchConfirmClaimGroup = useCallback(
    (payload) => {
      dispatch(confirmClaimGroup(payload));
    },
    [dispatch],
  );

  const dispatchClearClaimConfirmationErrors = useCallback(
    (payload) => {
      dispatch(clearClaimConfirmationErrors(payload));
    },
    [dispatch],
  );

  const dispatchGoToEditionPage = useCallback(
    (claimGroupId) => {
      dispatch(replace(getIssuesPageLink(claimGroupId)));
    },
    [dispatch],
  );

  const errors = useSelectActionError({
    action: createInvoiceForClaimGroup.toString(),
    indexer: confirmation?.claimGroupId,
  });

  const isInvoiceAlreadyPaid = errors.some(
    (error) => error.code === SPECIFIC_ERROR_CODES.INVOICE_ALREADY_PAID,
  );

  return (
    <$Wrapper>
      <NeedHelp clientId={AuthConfiguration.clientId} />

      <Grid fluid={isGridFluidActive}>
        <Row>
          <Col xs={12}>
            <OldPageHeader
              type="confirmation"
              dataTestId="claimGroupConfirmationTitle"
            />
          </Col>
          <Col xs={12}>
            <Confirmation
              sellerId={sellerId}
              confirmationLoadingStatus={currentConfirmationLoadingStatus}
              confirmationErrors={currentConfirmationErrors}
              resetPaymentFormErrors={() => {
                dispatchClearClaimConfirmationErrors({
                  claimGroupId: confirmation.claimGroupId,
                });
              }}
              isLoading={
                size(fileProducts) === 0 ||
                !confirmationIsLoaded ||
                (get(confirmation, ['shouldGenerateAndPayInvoice']) &&
                  !isInvoiceAlreadyPaid &&
                  (currentInvoice
                    ? getFromDataByKey(currentInvoice, 'isLoaded', false) ===
                      false
                    : true))
              }
              confirmationDatas={{
                ...confirmation,
                ...get(confirmation, ['claims', 0]),
                invoice: getFromDataByKey(currentInvoice, 'value', null),
              }}
              confirmationElements={{ followUp: true }}
              loginInfo={login}
              onValidate={(values) => {
                dispatchConfirmClaimGroup({ ...values, claimIds });
              }}
              assets={assets}
              products={products}
              translatedCarrirerMethodNames={translatedCarrirerMethodNames}
              goToEditionPage={() =>
                dispatchGoToEditionPage(confirmation.claimGroupId)
              }
              getClaimHandlingLink={(mode) =>
                getClaimHandlingLink(confirmation.claimGroupId, mode)
              }
              fileIsLoading={fileIsLoading}
              storeActorOptions={storeActorOptions}
            />
          </Col>
        </Row>
      </Grid>
    </$Wrapper>
  );
};

ClaimGroupConfirmation.propTypes = {
  fileProducts: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  assets: PropTypes.shape({}).isRequired,
  login: PropTypes.shape({}).isRequired,
  confirmationIsLoaded: PropTypes.bool.isRequired,
  confirmation: PropTypes.shape({}),
  translatedCarrirerMethodNames: PropTypes.shape({}).isRequired,
  currentInvoice: PropTypes.shape({}),
  currentConfirmationLoadingStatus: PropTypes.bool,
  currentConfirmationErrors: PropTypes.arrayOf(PropTypes.shape({})),
  getClaimHandlingLink: PropTypes.func.isRequired,
  fileIsLoading: PropTypes.bool.isRequired,
  productsFullInfo: PropTypes.func.isRequired,
};

ClaimGroupConfirmation.defaultProps = {
  confirmation: null,
  currentInvoice: null,
  currentConfirmationLoadingStatus: null,
  currentConfirmationErrors: null,
};

ClaimGroupConfirmation.displayName = 'ClaimGroupConfirmation';

export default ClaimGroupConfirmation;
