import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useDeepCompareEffect } from 'react-use';

import {
  ATTACHMENTS_PLACEMENTS,
  DOCUMENT_TYPES,
  RMA_DOCUMENT_TYPES,
} from '@savgroup-front-common/constants';
import { DocumentData } from '@savgroup-front-common/core/src/formatters/getDocumentNameTranslated';
import { printDocumentWithUrl } from '@savgroup-front-common/core/src/helpers';
import { useGetShippingLabel } from '@savgroup-front-common/core/src/hooks';
import { labelsValue } from '@savgroup-front-common/core/src/domains/carriers/selectors';
import {
  ActionCreators as ClaimsActionCreators,
  Selectors as ClaimsSelectors,
} from '@savgroup-front-common/core/src/domains/claims';
import {
  AdditionalClaimInformation,
  Document,
} from '@savgroup-front-common/types';
import { useMyAccountTypedSelector } from 'myaccount/hooks';

interface UseGetDocumentsArgs {
  documents?: Document[];
  claimId: string;
}
interface UseGetDocumentsReturnValue {
  allDocuments: DocumentData[];
  handlePrintDocument: (document: DocumentData) => void;
}

const useGetDocuments = ({
  documents = [],
  claimId,
}: UseGetDocumentsArgs): UseGetDocumentsReturnValue => {
  const { fileId } = useParams<{ fileId: string }>();

  const fullClaim = useMyAccountTypedSelector((state) =>
    ClaimsSelectors.getFullClaimById(state, { claimId }),
  );
  const dispatch = useDispatch();

  useDeepCompareEffect(() => {
    if (!fullClaim || Object.keys(fullClaim).length === 0) {
      dispatch(ClaimsActionCreators.loadFullClaimById({ claimId }));
    }
  }, [dispatch, claimId, fullClaim]);

  const claimAdditionalInformationDocuments: AdditionalClaimInformation[] =
    fullClaim?.additionalInformation
      ?.filter(
        (additionalInformation: AdditionalClaimInformation) =>
          additionalInformation.additionalInformationFileValue &&
          additionalInformation.additionalInformationFileValue
            .customerVisibility,
      )
      .flat() || [];

  const claimAdditionalInformationDocumentsEnhanced =
    claimAdditionalInformationDocuments.map(
      (claimAdditionalInformationDocument) => {
        const {
          additionalInformationFileValue,
          additionalInformationStringValue,
        } = claimAdditionalInformationDocument;
        const url = additionalInformationFileValue?.url;
        const fileName = additionalInformationFileValue?.fileName;
        const isBillRma = additionalInformationStringValue?.includes(
          RMA_DOCUMENT_TYPES.BILL_RMA,
        );

        return {
          ...additionalInformationFileValue,
          fileId,
          attachmentId: url,
          documentName: fileName || '',
          type: ATTACHMENTS_PLACEMENTS.ADDITIONAL_INFORMATION_DOCUMENTS,
          isBillRma,
        };
      },
    );

  const voucher =
    fullClaim?.documents?.filter(
      (document: Document) =>
        document.name !== DOCUMENT_TYPES.IMAGE && document.customerVisibility,
    ) || [];

  const { value: shippinglabelValue } =
    useMyAccountTypedSelector((state) => labelsValue(state, { fileId })) || [];
  const shippinglabels = shippinglabelValue || [];
  const shippingLabelsWithType = shippinglabels
    .filter((doc: DocumentData) => !doc.disabled && doc.customerVisibility)
    .map((doc: DocumentData) => {
      return {
        ...doc,
        type: DOCUMENT_TYPES.SHIPPING_LABEL,
      };
    });
  const clientDocument = documents.map((doc) => {
    return {
      ...doc,
      documentName: doc.originalFileName || '',
    };
  });
  const voucherDocument = voucher.map((doc: Document) => {
    return {
      ...doc,
      documentName: doc.name || '',
    };
  });
  const getShippingLabel = useGetShippingLabel({
    fileId,
  });

  const handlePrintDocument = useCallback(
    (document: DocumentData) => {
      if (document?.type === DOCUMENT_TYPES.SHIPPING_LABEL) {
        getShippingLabel();
      } else if (document.url) {
        printDocumentWithUrl({ documentUrl: document.url });
      }
    },
    [getShippingLabel],
  );
  const allDocuments = [
    ...clientDocument,
    ...voucherDocument,
    ...shippingLabelsWithType,
    ...claimAdditionalInformationDocumentsEnhanced,
  ];

  return { allDocuments: allDocuments || [], handlePrintDocument };
};

export default useGetDocuments;
