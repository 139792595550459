export enum TechnicalInterventionStatus {
  NONE = 'None',
  SCHEDULED = 'Scheduled',
  PLANNED = 'Planned',
  DISPATCHED = 'Dispatched',
  IN_PROGRESS = 'In Progress',
  COMPLETED = 'Completed',
  CANNOT_COMPLETE = 'Cannot Complete',
  CANCELED = 'Canceled',
  NEW = 'New',
  ASSIGNED = 'Assigned',
  ON_THE_WAY = 'OnTheWay',
  REPAIRED = 'Repaired',
  REPAIR_REFUSED_BY_CUSTOMER = 'RepairRefusedByCustomer',
  QUOTE_TO_BE_ISSUED = 'QuoteToBeIssued',
  QUOTE_ISSUED = 'QuoteIssued',
  SPARE_PART_REQUEST_TO_BE_MADE = 'SparePartRequestToBeMade',
  SPARE_PART_REQUEST_DONE = 'SparePartRequestDone',
  IN_TESTING = 'InTesting',
  RECORDER_DEPOSIT_REQUEST = 'RecorderDepositRequest',
  RECORDER_DEPOSIT_DONE = 'RecorderDepositDone',
  TECHNICALLY_IRREPARABLE = 'TechnicallyIrreparable',
  ECONOMICALLY_IRREPARABLE = 'EconomicallyIrreparable',
  WAITING_FOR_ADDITIONAL_INFORMATION = 'WaitingForAdditionalInformation',
  WORKSHOP_RETURN_REQUESTED = 'WorkshopReturnRequested',
  WORKSHOP_RETURN_DONE = 'WorkshopReturnDone',
  LOAN_DEPOSIT_REQUESTED = 'LoanDepositRequested',
  NEW_APPOINTMENT_TO_BE_SCHEDULED = 'NewAppointmentToBeScheduled',
  CUSTOMER_ABSENT = 'CustomerAbsent',
  INTERVENTION_REFUSED_BY_CUSTOMER = 'InterventionRefusedByCustomer',
  WRONG_CUSTOMER_ADDRESS = 'WrongCustomerAddress',
  SPARE_PART_ISSUE = 'SparePartIssue',
  INACCESSIBLE_PRODUCT = 'InaccessibleProduct',
  TECHNICIAN_UNFORESEEN = 'TechnicianUnforeseen',
}

export const TechnicalInterventionStatusMap = {
  Scheduled: [
    TechnicalInterventionStatus.SCHEDULED,
    'Planifié',
    TechnicalInterventionStatus.PLANNED,
  ],
};

export interface TechnicalIntervention {
  startTimeUtc?: string;
  endTimeUtc?: string;
  status: TechnicalInterventionStatus;
  description: string;
  createdDate: string;
  isReSchedulable?: boolean;
  workType: {
    id: string;
    label: string;
  };
  id?: string;
}
