import { useQuery } from 'react-query';

import { ClaimService } from '../../../api';

export const useGetClaimContextsByGroupIdQuery = ({
  claimGroupId,
}: {
  claimGroupId?: string;
}) => {
  const { data: claimContexts, isLoading } = useQuery(
    ['useGetClaimContextsByGroupIdQuery', { claimGroupId }],
    async () => {
      if (!claimGroupId) {
        return undefined;
      }

      const response = await ClaimService.getClaimContextsByGroupIdQuery({
        claimGroupId,
      });

      if (response.failure) {
        return undefined;
      }

      return response.value;
    },
  );

  return {
    claimContexts,
    isLoading,
  };
};
