import find from 'lodash/find';
import flatMap from 'lodash/flatMap';
import get from 'lodash/get';
import head from 'lodash/head';
import isEmpty from 'lodash/isEmpty';
import matches from 'lodash/matches';
import size from 'lodash/size';
import { matchPath } from 'react-router-dom';
import { LOCATION_CHANGE, push, replace } from 'redux-first-history';
import {
  all,
  call,
  fork,
  put,
  race,
  select,
  take,
  takeEvery,
} from 'redux-saga/effects';

import { logVerbose } from '@savgroup-front-common/configuration/src/appInsights/AppInsights';
import { SPECIFIC_ERROR_CODES } from '@savgroup-front-common/constants';
import {
  API_COMMON_ERROR,
  CLAIM_STATES,
} from '@savgroup-front-common/constants/src/shared';
import { getCarrierBrand } from '@savgroup-front-common/core/src/helpers';
import {
  ActionTypes as CarrierActionTypes,
  ActionCreators as CarriersActionCreators,
  Selectors as CarrierSelectors,
} from '@savgroup-front-common/core/src/domains/carriers';
import {
  ActionCreators as ClaimActionCreators,
  Selectors as ClaimSelectors,
} from '@savgroup-front-common/core/src/domains/claims';
import * as ClaimActionTypes from '@savgroup-front-common/core/src/domains/claims/actionTypes';
import {
  ActionCreators as SellerActionCreators,
  ActionTypes as SellerActionTypes,
} from '@savgroup-front-common/core/src/domains/sellerConfiguration';
import {
  pathname,
  selectUserId,
} from '@savgroup-front-common/core/src/domains/selectors';
import { ROUTES } from 'myaccount/view/app';

import {
  ActionCreators as ClaimCreators,
  Selectors as ClaimSelector,
  ActionTypes as ClaimTypes,
} from '../Claim';
import {
  ActionTypes as ClaimEditionActionTypes,
  saga as claimEditionSaga,
} from '../Claim/ClaimEdition';
import {
  CLAIM_GROUP_STATES,
  currentClaimGroup,
  currentClaimGroupConfirmation,
  currentClaimGroupEditionStateSelector,
  currentClaimGroupIdSelector,
  currentClaimGroupStateSelector,
  currentClaims,
  currentGroupHandlingInfo,
  currentIssues,
  isHomePickupSelector,
} from '../Claim/claimGroupSelector';
import { loadOrdersDataWorker } from '../Orders/saga';
import {
  ActionCreators as OwnerActionCreators,
  ActionTypes as OwnerActionTypes,
  Selectors as OwnerSelectors,
} from '../owner';
import {
  ActionCreators as PaymentsActionCreators,
  ActionTypes as PaymentsActionTypes,
} from '../payments';
import { addresses } from '../ProfileManagement/selectors';

import { LOAD_NEXT_SCREEN_FOR_CLAIM_GROUP } from './actionTypes';

function* getFullProductInfoWorker(claimId) {
  const products = yield select(ClaimSelectors.products);
  const productId = get(products.toJS(), [claimId, 'value', 'productId']);
  const ownerId = yield select(selectUserId);

  let productsFullData = yield select(OwnerSelectors.fullProducts);

  if (!productsFullData || !productsFullData.getIn([productId, 'value'])) {
    yield all([
      put(OwnerActionCreators.getProductFullInfo({ ownerId, productId })),
      take(
        matches({
          type: OwnerActionTypes.GET_PRODUCT_FULL_INFO.END,
          meta: {
            ownerId,
            productId,
          },
        }),
      ),
    ]);
  }

  productsFullData = yield select(OwnerSelectors.fullProducts);
  const sellerId = get(
    productsFullData.getIn([productId, 'value']),
    'sellerId',
  );
  const sellerProductId = get(
    productsFullData.getIn([productId, 'value']),
    'sellerProductId',
  );
  const sellerInfo = yield select(ClaimSelectors.sellerInfo);

  yield all([
    put(SellerActionCreators.getSellerConfiguration(sellerId)),
    take(SellerActionTypes.GET_SELLER_CONFIGURATION.END),
  ]);

  if (
    !sellerInfo.getIn([sellerId, sellerProductId, 'value']) &&
    sellerId &&
    sellerProductId
  ) {
    yield put(ClaimActionCreators.getSellerInfo({ sellerId, sellerProductId }));
  }
}

function* loadDataForIssueReasonSolutionScreenWorker(claim, claimId) {
  // TODO should load data for ReasonComments and uploaded files too
  yield put(ClaimActionCreators.getReasonComment(claimId));

  switch (get(claim, 'state')) {
    case CLAIM_STATES.WAITING_FOR_ISSUE: {
      yield all([
        put(ClaimActionCreators.loadIssuesByClaim(claimId)),
        take(ClaimActionTypes.LOAD_ISSUES_OPTIONS_BY_CLAIM.SUCCEEDED),
      ]);
      const issuesState = get(yield select(currentIssues), claimId);

      if (issuesState) {
        const issues = issuesState.get('value');

        if (size(issues) === 1) {
          yield put(
            ClaimActionCreators.selectIssueForClaim({
              claimId,
              issueId: get(head(issues), 'id'),
            }),
          );
        }
      }
      break;
    }
    case CLAIM_STATES.WAITING_FOR_REASON:
      yield all([
        put(ClaimActionCreators.loadIssuesByClaim(claimId)),
        put(ClaimActionCreators.loadReasonsByClaim(claimId)),
      ]);
      break;
    case CLAIM_STATES.WAITING_FOR_SOLUTION: {
      yield all([
        put(ClaimActionCreators.loadIssuesByClaim(claimId)),
        put(ClaimActionCreators.loadReasonsByClaim(claimId)),
        put(ClaimActionCreators.loadSolutionsByClaim(claimId)),
        take(
          matches({
            type: ClaimActionTypes.LOAD_SOLUTIONS_OPTIONS_BY_CLAIM.SUCCEEDED,
            meta: {
              claimId,
            },
          }),
        ),
      ]);
      break;
    }
    default:
      yield all([
        put(ClaimActionCreators.loadIssuesByClaim(claimId)),
        put(ClaimActionCreators.loadReasonsByClaim(claimId)),
        put(ClaimActionCreators.loadSolutionsByClaim(claimId)),
      ]);
      break;
  }
}
function* loadDataForGroupIssueScreenWatcher(claim, claimId) {
  yield all([
    call(loadDataForIssueReasonSolutionScreenWorker, claim, claimId),
    call(getFullProductInfoWorker, claimId),
  ]);
}

export function* loadDataForHandlingInfoScreenWorker(
  claimGroupId,
  handlingMode,
) {
  // TODO handling data should be loaded at group level
  yield all([
    put(ClaimActionCreators.loadHandlingByClaimGroup({ claimGroupId })),
    put(
      ClaimActionCreators.loadCarriersByClaimGroup({
        claimGroupId,
        handlingMode,
      }),
    ),
    put(ClaimActionCreators.loadCarrierSummaryByClaimGroup({ claimGroupId })),
    take(ClaimActionTypes.LOAD_CARRIERS_OPTIONS_BY_CLAIM_GROUP.END),
  ]);

  const carriersState = yield select(ClaimSelectors.groupCarriers);
  const carriers = carriersState.getIn([claimGroupId, handlingMode, 'value']);
  const potentialHomePickup = get(carriers, ['Deposit_HomeDelivery', '0']);

  if (potentialHomePickup) {
    const carriersProducts = yield select(CarrierSelectors.carriersProducts);

    if (!carriersProducts) {
      yield all([
        put(CarriersActionCreators.loadCarriersProducts()),
        take(CarrierActionTypes.LOAD_CARRIERS_PRODUCTS.END),
      ]);
    }
    const transportMethods = yield select(CarrierSelectors.transportMethods);

    if (!transportMethods) {
      yield all([
        put(CarriersActionCreators.loadTransportMethods()),
        take(CarrierActionTypes.LOAD_TRANSPORT_METHODS.END),
      ]);
    }

    const isHomePickup = yield select(isHomePickupSelector);

    if (isHomePickup) {
      const carrierName = getCarrierBrand(potentialHomePickup.carrierName);
      const addressesState = yield select(addresses);
      const address =
        find(addressesState, { isDefault: true }) || head(addressesState);

      yield put(
        CarriersActionCreators.loadPickupSchedule({
          countryCode: address.countryCode ? address.countryCode : 'FR',
          carrier: carrierName,
          startTimeUtc: new Date().toISOString(),
        }),
      );
    }
  }
}

function* loadDataForConfirmationScreenWorker(claimGroupId, claimIds) {
  yield fork(loadOrdersDataWorker);
  yield all([
    put(ClaimActionCreators.loadConfirmationInfoByClaimGroup(claimGroupId)),
    take(
      matches({
        type: ClaimActionTypes.LOAD_CONFIRMATION_INFO_BY_CLAIM_GROUP.END,
        meta: {
          claimGroupId,
        },
      }),
    ),
  ]);
  const confirmationState = (yield select(currentClaimGroupConfirmation)).get(
    'value',
  );

  if (confirmationState) {
    for (const claimId of claimIds) {
      yield call(getFullProductInfoWorker, claimId);
    }
    const {
      carrierDepositName,
      carrierDeliveryName,
      shouldGenerateAndPayInvoice,
    } = confirmationState;

    if (carrierDepositName || carrierDeliveryName) {
      const carriersProducts = yield select(CarrierSelectors.carriersProducts);

      if (!carriersProducts) {
        yield all([
          put(CarriersActionCreators.loadCarriersProducts()),
          take(CarrierActionTypes.LOAD_CARRIERS_PRODUCTS.END),
        ]);
      }
      const transportMethods = yield select(CarrierSelectors.transportMethods);

      if (!transportMethods) {
        yield all([
          put(CarriersActionCreators.loadTransportMethods()),
          take(CarrierActionTypes.LOAD_TRANSPORT_METHODS.END),
        ]);
      }
    }

    if (shouldGenerateAndPayInvoice) {
      yield put(
        PaymentsActionCreators.createInvoiceForClaimGroup({ claimGroupId }),
      );

      const [, errorResponse] = yield race([
        take(
          matches({
            type: PaymentsActionTypes.CREATE_INVOICE_FOR_CLAIM_GROUP.SUCCEEDED,
            meta: {
              claimGroupId,
            },
          }),
        ),
        take(
          matches({
            type: PaymentsActionTypes.CREATE_INVOICE_FOR_CLAIM_GROUP.ERRORED,
            meta: {
              claimGroupId,
            },
          }),
        ),
      ]);

      const errors = errorResponse?.errors || [];

      if (
        errors.length &&
        errors.some(
          (error) => error.code === SPECIFIC_ERROR_CODES.INVOICE_ALREADY_PAID,
        )
      ) {
        return;
      }

      yield all([
        put(PaymentsActionCreators.getOpenedInvoices({ claimGroupId })),
        take(
          matches({
            type: PaymentsActionTypes.GET_OPENED_INVOICES.END,
            meta: {
              id: claimGroupId,
            },
          }),
        ),
      ]);
    } else {
      yield all([
        put(PaymentsActionCreators.getOpenedInvoices({ claimGroupId })),
        take(
          matches({
            type: PaymentsActionTypes.GET_OPENED_INVOICES.END,
            meta: {
              id: claimGroupId,
            },
          }),
        ),
      ]);
    }
  }
}

function* loadSolutionsWorker(claimId) {
  yield all([
    put(ClaimActionCreators.loadSolutionsByClaim(claimId)),
    take(
      matches({
        type: ClaimActionTypes.LOAD_SOLUTIONS_OPTIONS_BY_CLAIM.SUCCEEDED,
        meta: { claimId },
      }),
    ),
  ]);
}

function* handleStoreActor({ claimIds }) {
  yield all(
    flatMap(claimIds, (claimId) => [
      put(ClaimActionCreators.loadConfirmationInfoByClaim(claimId)),
      take(ClaimActionTypes.LOAD_CONFIRMATION_INFO_BY_CLAIM.END),
    ]),
  );

  const claimsConfirmation = yield select((state) =>
    ClaimSelectors.claimsConfirmationByClaimIdsSelector(state, {
      claimIds,
    }),
  );
  const hasToGetStoreActorOptions = claimsConfirmation.find(
    ({ additionalElements }) => additionalElements.includes('StoreActor'),
  );

  if (hasToGetStoreActorOptions) {
    yield all(
      flatMap(claimIds, (claimId) => [
        put(ClaimActionCreators.loadStoreActorByClaimId({ claimId })),
        take(
          matches({
            type: ClaimActionTypes.LOAD_STORE_ACTOR_BY_CLAIM_ID.END,
            meta: {
              claimId,
              indexer: claimId,
            },
          }),
        ),
      ]),
    );
  }
}

function* issueReasonSolutionWatcher() {
  yield takeEvery(
    ClaimActionTypes.SELECT_ISSUE_FOR_CLAIM.END,
    function* loadReasons(action) {
      const claimId = get(action, ['meta', 'claimId']);

      yield put(ClaimActionCreators.loadReasonsByClaim(claimId));
    },
  );

  yield takeEvery(
    ClaimActionTypes.SELECT_REASON_FOR_CLAIM.END,
    function* loadSolutionsAfterReason(action) {
      const { solutionPrice, solutionId, reasonId, previousReasonId, claimId } =
        get(action, ['meta']) || {};

      if (reasonId === previousReasonId && solutionId) {
        yield put(
          ClaimActionCreators.selectSolutionForClaim({
            claimId,
            solutionTypeId: solutionId,
            solutionPrice,
          }),
        );
      }
      yield loadSolutionsWorker(claimId);
    },
  );

  yield takeEvery(
    ClaimActionTypes.SET_REASON_ADDITIONAL_INFORMATION.END,
    function* loadSolutionsAfterAdditionalInfo(action) {
      const { claimId, informations } = get(action, ['meta']) || {};

      if (!isEmpty(informations)) {
        yield loadSolutionsWorker(claimId);
      }
    },
  );
}

function* loadScreenDataForClaimGroupWatcher(claimGroupId) {
  const claimIds = yield select(currentClaimGroup);
  const currentClaimGroupEditionState = yield select(
    currentClaimGroupEditionStateSelector,
  );

  switch (currentClaimGroupEditionState) {
    case CLAIM_GROUP_STATES.CLAIM_GROUP_ISSUES:
      for (const claimId of claimIds) {
        const claim = get(yield select(currentClaims), claimId);

        if (get(claim, 'state') === CLAIM_STATES.WAITING_FOR_ISSUE) {
          yield put(push(`${ROUTES.ISSUES}?claimGroupId=${claimGroupId}`));
          break;
        }
        yield call(loadDataForGroupIssueScreenWatcher, claim, claimId);
      }
      yield call(loadOrdersDataWorker, { payload: { forceRefresh: true } });
      break;
    case CLAIM_GROUP_STATES.CLAIM_GROUP_HANDLING_DEPOSIT:
      yield call(loadDataForHandlingInfoScreenWorker, claimGroupId, 'Deposit');
      break;
    case CLAIM_GROUP_STATES.CLAIM_GROUP_HANDLING_DELIVERY:
      yield call(loadDataForHandlingInfoScreenWorker, claimGroupId, 'Delivery');
      break;
    case CLAIM_GROUP_STATES.CLAIM_GROUP_CONFIRMATION:
      yield call(loadDataForConfirmationScreenWorker, claimGroupId, claimIds);
      break;
    case CLAIM_STATES.CLOSED:
    default:
      // eslint-disable-next-line no-console
      console.warn('Unhandled state!');
      break;
  }
}

export const getIssuesPageLink = (claimGroupId) =>
  ROUTES.CLAIM_GROUP_ISSUES.replace(':claimGroupId', claimGroupId);

const getHandlingPageLink = (claimGroupId, handlingMode) =>
  ROUTES.CLAIM_GROUP_HANDLING.replace(':claimGroupId', claimGroupId).replace(
    ':handlingMode',
    handlingMode,
  );

const getConfirmationPageLink = (claimGroupId) =>
  ROUTES.CLAIM_GROUP_CONFIRMATION.replace(':claimGroupId', claimGroupId);
const getFilePageLink = (fileId) =>
  ROUTES.FILE_FOLLOWUPS.replace(':fileId', fileId);

function* loadPreviousScreenForClaimGroup(claimGroupId) {
  const currentClaimGroupEditionState = yield select(
    currentClaimGroupEditionStateSelector,
  );

  switch (currentClaimGroupEditionState) {
    case CLAIM_GROUP_STATES.CLAIM_GROUP_HANDLING_DEPOSIT:
      yield put(replace(getIssuesPageLink(claimGroupId)));
      break;
    case CLAIM_GROUP_STATES.CLAIM_GROUP_HANDLING_DELIVERY:
      {
        yield all([
          put(ClaimActionCreators.loadHandlingByClaimGroup({ claimGroupId })),
          take(ClaimActionTypes.LOAD_HANDLING_BY_CLAIM_GROUP.END),
        ]);
        const groupHandlingInfo = yield select(currentGroupHandlingInfo);
        const { hasDeposit, hasHome } =
          (groupHandlingInfo && groupHandlingInfo.get('value')) || {};

        if (hasDeposit || hasHome) {
          yield put(replace(getHandlingPageLink(claimGroupId, 'deposit')));
        } else {
          yield put(replace(getIssuesPageLink(claimGroupId)));
        }
      }
      break;
    case CLAIM_GROUP_STATES.CLAIM_GROUP_CONFIRMATION:
      // TODO: depending on the handling info we should either go back to deposit or delivery
      yield put(replace(getHandlingPageLink(claimGroupId, 'delivery')));
      break;
    case CLAIM_GROUP_STATES.CLAIM_GROUP_ISSUES:
      // TODO: change this selector when flow will be changed
      yield put(replace(ROUTES.PRODUCTS));
      break;
    case CLAIM_STATES.CLOSED:
    default:
      // eslint-disable-next-line no-console
      console.warn('Unhandled state!');
      break;
  }
}

function* loadNextScreenForClaimGroupWorker() {
  yield put(LOAD_NEXT_SCREEN_FOR_CLAIM_GROUP.start());

  const claimGroupId = yield select(currentClaimGroupIdSelector);
  const currentClaimGroupEditionState = yield select(
    currentClaimGroupEditionStateSelector,
  );

  switch (currentClaimGroupEditionState) {
    case CLAIM_GROUP_STATES.CLAIM_GROUP_ISSUES:
    case CLAIM_GROUP_STATES.CLAIM_GROUP_HANDLING_DEPOSIT:
      {
        yield all([
          put(ClaimActionCreators.loadHandlingByClaimGroup({ claimGroupId })),
          take(ClaimActionTypes.LOAD_HANDLING_BY_CLAIM_GROUP.END),
        ]);
        const groupHandlingInfo = yield select(currentGroupHandlingInfo);
        const { hasDelivery, hasDeposit, hasHome } =
          (groupHandlingInfo && groupHandlingInfo.get('value')) || {};
        const handlingErrored =
          groupHandlingInfo && groupHandlingInfo.get('hasErrors');

        if (
          (hasDeposit || handlingErrored || hasHome) &&
          currentClaimGroupEditionState !==
            CLAIM_GROUP_STATES.CLAIM_GROUP_HANDLING_DEPOSIT
        ) {
          yield put(replace(getHandlingPageLink(claimGroupId, 'deposit')));
        } else if (hasDelivery) {
          yield put(replace(getHandlingPageLink(claimGroupId, 'delivery')));
        } else {
          yield put(replace(getConfirmationPageLink(claimGroupId)));
        }
      }
      break;

    case CLAIM_GROUP_STATES.CLAIM_GROUP_HANDLING_DELIVERY:
      yield put(replace(getConfirmationPageLink(claimGroupId)));
      break;
    case CLAIM_GROUP_STATES.CLAIM_GROUP_CONFIRMATION:
      {
        const claimIds = (yield select(ClaimSelectors.claimGroups)).getIn([
          claimGroupId,
          'value',
        ]);
        const claimId = head(claimIds);

        yield all([
          put(ClaimCreators.loadClaimSummary(claimId)),
          take(ClaimTypes.LOAD_CLAIM_SUMMARY.END),
        ]);
        const { fileId } = yield select(ClaimSelector.claimSummary);

        yield put(replace(getFilePageLink(fileId)));
      }
      break;
    default:
      logVerbose(
        new Error(
          `loadNextScreenForClaimGroupWorker: Unhandled state! [${claimGroupId}]`,
        ),
        { currentClaimGroupEditionState },
      );
      break;
  }

  yield put(LOAD_NEXT_SCREEN_FOR_CLAIM_GROUP.success());
  yield put(LOAD_NEXT_SCREEN_FOR_CLAIM_GROUP.end());
}
function* loadNextScreenForClaimGroupWatcher() {
  yield takeEvery(
    LOAD_NEXT_SCREEN_FOR_CLAIM_GROUP.BASE,
    loadNextScreenForClaimGroupWorker,
  );
}

function* onNextStepTriggerWorker() {
  yield put(LOAD_NEXT_SCREEN_FOR_CLAIM_GROUP.base());
}
function* onNextStepTriggerWatcher() {
  yield takeEvery(
    [
      ClaimActionTypes.SELECT_HANDLING_FOR_CLAIM_GROUP.SUCCEEDED,
      ClaimEditionActionTypes.GO_FORWARD_CLAIM_GROUP_EDITION,
      ClaimActionTypes.CONFIRM_CLAIM_GROUP.SUCCEEDED,
    ],
    onNextStepTriggerWorker,
  );
}

export function* saga() {
  yield all([loadNextScreenForClaimGroupWatcher(), onNextStepTriggerWatcher()]);
}

export default function* mainSaga() {
  yield fork(claimEditionSaga);
  const claimGroupId = yield select(currentClaimGroupIdSelector);

  yield all([put(ClaimActionCreators.loadClaimGroupById(claimGroupId))]);
  const [, error] = yield race([
    take(ClaimActionTypes.LOAD_CLAIM_GROUP_BY_ID.SUCCEEDED),
    take(ClaimActionTypes.LOAD_CLAIM_GROUP_BY_ID.ERRORED),
  ]);

  if (
    error &&
    size(error.errors) > 0 &&
    error.errors[0].code === API_COMMON_ERROR.DATA_NOT_FOUND
  ) {
    yield put(replace(ROUTES.ISSUES));

    return;
  }

  const claimIds = (yield select(ClaimSelectors.claimGroups)).getIn(
    [claimGroupId, 'value'],
    [],
  );

  for (const claimId of claimIds) {
    const claimByIdWasLoaded = yield select((state) =>
      ClaimSelectors.selectLoadClaimByIdWasLoaded(state, { claimId }),
    );

    if (!claimByIdWasLoaded) {
      yield all([
        put(ClaimActionCreators.loadClaimById(claimId)),
        take(ClaimActionTypes.LOAD_CLAIM_BY_ID.END),
      ]);
    }
    yield all([
      put(ClaimActionCreators.loadClaimProduct(claimId)),
      take(
        matches({
          type: ClaimActionTypes.LOAD_CLAIM_PRODUCT.END,
          meta: {
            claimId,
          },
        }),
      ),
    ]);
  }

  const currentClaimGroupEditionState = yield select(
    currentClaimGroupEditionStateSelector,
  );
  const currentClaimGroupState = yield select(currentClaimGroupStateSelector);
  const path = yield select(pathname);

  const isConfirmationPath = matchPath(path, {
    path: ROUTES.CLAIM_GROUP_CONFIRMATION,
    exact: false,
    strict: false,
  });

  if (isConfirmationPath) {
    yield handleStoreActor({ claimIds });
  }

  if (!currentClaimGroupEditionState) {
    switch (currentClaimGroupState) {
      case CLAIM_GROUP_STATES.CLAIM_GROUP_ISSUES:
        yield put(replace(getIssuesPageLink(claimGroupId)));
        break;
      case CLAIM_GROUP_STATES.CLAIM_GROUP_HANDLING_DEPOSIT:
        yield put(replace(getHandlingPageLink(claimGroupId, 'deposit')));
        break;
      case CLAIM_GROUP_STATES.CLAIM_GROUP_HANDLING_DELIVERY:
        yield put(replace(getHandlingPageLink(claimGroupId, 'delivery')));
        break;
      case CLAIM_GROUP_STATES.CLAIM_GROUP_CONFIRMATION:
        yield put(replace(getConfirmationPageLink(claimGroupId)));
        break;
      default:
        // eslint-disable-next-line no-console
        console.warn('Unhandled state!');
    }
  }

  yield call(loadScreenDataForClaimGroupWatcher, claimGroupId);

  yield takeEvery(
    LOCATION_CHANGE,
    loadScreenDataForClaimGroupWatcher,
    claimGroupId,
  );

  yield takeEvery(
    ClaimEditionActionTypes.GO_BACK_CLAIM_GROUP_EDITION,
    loadPreviousScreenForClaimGroup,
    claimGroupId,
  );

  yield fork(issueReasonSolutionWatcher);
}
