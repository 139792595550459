import { DefaultTheme } from 'styled-components';

interface GetTextColorBasedOnPropsArgs {
  theme: DefaultTheme;
  disabled?: boolean;
  default?: boolean;
  clear?: boolean;
  alert?: boolean;
  danger?: boolean;
  heading?: boolean;
  paragraph?: boolean;
  primary?: boolean;
  secondary?: boolean;
  error?: boolean;
  isError?: boolean;
  warning?: boolean;
  isWarning?: boolean;
  success?: boolean;
  isSuccess?: boolean;
  $isNewBoEnabled?: boolean;
}

const getTextColorBasedOnProps = (
  { theme, ...props }: GetTextColorBasedOnPropsArgs,
  defaultValue?: string,
): string => {
  if (props.$isNewBoEnabled && props.primary) {
    return theme.newUI.defaultColors.primary;
  }
  if (props.$isNewBoEnabled && props.secondary) {
    return theme.newUI.defaultColors.text;
  }
  if (props.default) {
    return theme.colors.default;
  }
  if (props.disabled) {
    return theme.colors.disabled;
  }
  if (props.clear) {
    return theme.colors.white;
  }
  if (props.alert) {
    return theme.colors.alert;
  }
  if (props.danger) {
    return theme.colors.danger;
  }
  if (props.heading) {
    return theme.colors.headingTextColor;
  }
  if (props.paragraph) {
    return theme.colors.paragraphTextColor;
  }
  if (props.primary) {
    return theme.colors.primary;
  }
  if (props.secondary) {
    return theme.colors.secondary;
  }

  if (props.error || props.isError) {
    return theme.colors.danger;
  }
  if (props.warning || props.isWarning) {
    return theme.colors.alert;
  }
  if (props.success || props.isSuccess) {
    return theme.colors.success;
  }

  if (props.$isNewBoEnabled && !defaultValue) {
    return theme.newUI.defaultColors.text;
  }

  return defaultValue || theme.colors.mainTextColor;
};

export default getTextColorBasedOnProps;
