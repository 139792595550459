import get from 'lodash/get';
import { useDispatch } from 'react-redux';
import { useDeepCompareEffect } from 'react-use';

import { DOCUMENT_STATUS } from '@savgroup-front-common/constants/src/shared';

import { ActionCreators as ClaimsActionCreators } from '../../../../../domains/claims';

interface UseLoadFullClaimInfoArgs {
  claimId: string;
  type: string;
  getDocumentStatusByFileIdValue?: Record<string, string>;
}

const useLoadFullClaimInfo = ({
  getDocumentStatusByFileIdValue,
  type,
  claimId,
}: UseLoadFullClaimInfoArgs): void => {
  const dispatch = useDispatch();
  const documentStatus = get(getDocumentStatusByFileIdValue, type);

  useDeepCompareEffect(() => {
    if (documentStatus === DOCUMENT_STATUS.CREATED) {
      dispatch(
        ClaimsActionCreators.loadFullClaimById({ claimId, forceRefresh: true }),
      );
    }
  }, [documentStatus, dispatch, claimId]);
};

export default useLoadFullClaimInfo;
