import { fromJS, Map } from 'immutable';
import get from 'lodash/get';
import head from 'lodash/head';
import { v4 } from 'uuid';

export function onFullClaimLoading(state, { meta }) {
  const claimId = get(meta, ['claimId']);

  if (!claimId) {
    return state;
  }

  return state.updateIn(
    ['fullClaims', claimId],
    Map({
      isLoaded: false,
      isDirty: false,
      value: {
        internalId: v4(),
      },
      internalId: v4(),
    }),
    (oldValue) => oldValue.set('isLoaded', false).set('isDirty', true),
  );
}

export function onFullClaimLoadingErrored(state, { meta }) {
  const claimId = get(meta, 'claimId');

  if (!claimId) {
    return state;
  }

  // TODO errors
  return state.updateIn(['fullClaims', claimId], (oldValue) =>
    oldValue.set('isDirty', true).set('hasErrors', true),
  );
}

export function onFullClaimSuccess(state, { payload, meta }) {
  const claimId = get(meta, ['claimId']);
  const claim = get(payload, ['value']);

  if (!claimId) {
    return state;
  }
  const document = head(get(claim, 'documents'));
  const newState = state.setIn(
    ['fullClaims', claimId],
    fromJS({ isLoaded: true, isDirty: false }).setIn(['value'], claim),
  );

  if (document) {
    return newState.updateIn(['claimUploads', claimId], {}, (oldValue) => {
      const documentName = get(document, 'name');

      return {
        ...oldValue,
        [documentName]: {
          isLoaded: true,
          isDirty: false,
          value: {
            fileName: get(document, 'originalFileName'),
            progress: 100,
          },
          fileName: get(document, 'originalFileName'),
          name: get(document, 'originalFileName'),
          progress: 100,
          hasErrors: false,
        },
      };
    });
  }

  return newState;
}
