import { MessageType } from './MessageType';

export interface BaseAutocompleteOption {
  value: string;
  label: string;
  description?: string | MessageType;
  isNew?: boolean;
  isDefault?: boolean;
  isHidden?: boolean;
}

export type BaseAutocompleteOptionForHookForm = Omit<
  BaseAutocompleteOption,
  'description'
>;

export interface AutocompleteOption<T = undefined>
  extends BaseAutocompleteOption {
  data?: T;
}

export type AutocompleteOptionForHookForm<T = undefined> = Omit<
  AutocompleteOption<T>,
  'description'
>;

export interface StrictAutocompleteOption<T>
  extends Omit<AutocompleteOption<T>, 'data'> {
  data: T;
}

export type StrictAutocompleteOptionForHookForm<T = undefined> = Omit<
  StrictAutocompleteOption<T>,
  'description'
>;

export interface GroupAutocompleteOption {
  label: string;
  options: AutocompleteOption[];
}

export interface GroupStrictAutocompleteOption<T = undefined> {
  label: string;
  options: StrictAutocompleteOption<T>[];
}

export const isGroupedOptionsType = <T>(
  arrayToBeDetermined: unknown,
): arrayToBeDetermined is ReadonlyArray<T> => {
  return (
    Array.isArray(arrayToBeDetermined) &&
    arrayToBeDetermined.every(
      (element) => 'label' in element && 'options' in element,
    )
  );
};

export function isOptionType(arg: any): arg is BaseAutocompleteOption {
  return (
    (arg && 'value' in arg && typeof arg.value === 'string') ||
    typeof arg.value === 'boolean'
  );
}
