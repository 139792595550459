import React, { useState } from 'react';
import { Controller } from 'react-hook-form';

import {
  Autocomplete,
  Form,
  FormGroup,
} from '@savgroup-front-common/core/src/atoms/Form';
import { safeFormattedIntlString } from '@savgroup-front-common/core/src/formatters';
import { BaseLoader } from '@savgroup-front-common/core/src/molecules/BaseLoader';
import StepLayout from 'myaccount/view/app/NewLayout/StepLayout/StepLayout';

import useClaimGroupReason from './ClaimGroupReasonPage.hooks';
import { ClaimGroupReasonValues } from './ClaimGroupReasonPage.types';
import ClaimGroupReasonPageAdditionalInformationsContent from './ClaimGroupReasonPageAdditionalInformationsContent';
import ClaimGroupReasonPageCommentContent from './ClaimGroupReasonPageCommentContent';
import ClaimGroupReasonPageDiagnosticTreeEntry from './DiagnosticTreeEntry/ClaimGroupReasonPageDiagnosticTreeEntry';
import { neededInformationFiltered } from './helpers/reason.adapters';
import messages from './messages';

const ClaimGroupReasonPage: React.FC = () => {
  const [isRefuseDiagnosticTree, setIsRefuseDiagnosticTree] =
    useState<boolean>(false);
  const {
    reasons,
    reasonAdapted,
    isMultiProduct,
    onSubmit,
    formContext,
    isLoading,
    isLoadingSubmit,
    claimGroupId,
    diagnosticIsAlreadyMade,
  } = useClaimGroupReason();

  const {
    control,
    formState: { errors },
  } = formContext;

  const options = reasons.map((reason) => ({
    label: reason.name,
    value: reason.id,
  }));

  const neededInformationFilteredResult = neededInformationFiltered(
    reasonAdapted?.neededInformation,
    isMultiProduct,
  );

  const isDiagnosticTreeAvailable =
    reasonAdapted?.isDiagnosticTreeAvailable &&
    !isRefuseDiagnosticTree &&
    !diagnosticIsAlreadyMade;

  const isDiagnosticTreeUnavailable =
    !reasonAdapted?.isDiagnosticTreeAvailable ||
    isRefuseDiagnosticTree ||
    diagnosticIsAlreadyMade;

  const shouldShowReasonContent = Boolean(
    neededInformationFilteredResult?.length && isDiagnosticTreeUnavailable,
  );

  if (isLoading) {
    return <BaseLoader />;
  }

  return (
    <Form<ClaimGroupReasonValues> formContext={formContext} onSubmit={onSubmit}>
      <StepLayout
        isLoading={isLoadingSubmit}
        hasFooter={!isDiagnosticTreeAvailable || isRefuseDiagnosticTree}
        minHeight
      >
        <FormGroup dataTestId="dropdown-select-reason">
          <Controller
            control={control}
            name="reason"
            render={({ field }) => (
              <Autocomplete
                name={field.name}
                options={options}
                isRequired
                ref={field.ref}
                onChange={field.onChange}
                value={field.value}
                errors={errors}
                label={safeFormattedIntlString(messages.selectReason)}
                shouldAutoSelectFirstOptions={options.length === 1}
              />
            )}
          />
        </FormGroup>
        {shouldShowReasonContent && (
          <ClaimGroupReasonPageAdditionalInformationsContent
            formContext={formContext}
            neededInformation={neededInformationFilteredResult as any}
          />
        )}
        {shouldShowReasonContent && (
          <ClaimGroupReasonPageCommentContent formContext={formContext} />
        )}
        {isDiagnosticTreeAvailable && (
          <ClaimGroupReasonPageDiagnosticTreeEntry
            claimGroupId={claimGroupId}
            diagnosticTreeUniqueName={
              reasonAdapted?.diagnosticTreeUniqueName || ''
            }
            setIsRefuseDiagnosticTree={setIsRefuseDiagnosticTree}
          />
        )}
      </StepLayout>
    </Form>
  );
};

ClaimGroupReasonPage.displayName = 'ClaimGroupReasonPage';

export default ClaimGroupReasonPage;
