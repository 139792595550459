import { filter, maxBy } from 'lodash';
import React, { FunctionComponent } from 'react';
import { Col, Row } from 'react-styled-flexboxgrid';

import { Heading } from '@savgroup-front-common/core/src/atoms/Heading';
import { FileSummary } from '@savgroup-front-common/types';
import { ActionLabel } from 'myaccount/view/components/ActionLabel';

import { CardSpacer } from '../../../pages/FileFollowupsPage/FileFollowups/FileFollowups.styles';
import { CustomerCommentDisplay } from '../../CustomerCommentDisplay';

import { useActiveStatusTemplateContainer } from './ActiveStatusTemplate.hooks';
import ActiveStatusTemplateContent from './ActiveStatusTemplateContent';

const NOT_TRANSLATED_LABEL = 'NOT TRANSLATED LABEL';

interface ActiveStatusTemplateContainerProps {
  fileId: string;
  confirmationClaimGroupValue?: {
    actorId: string;
    storeActorId: string;
  };
  fileSummary?: FileSummary;
  claimConfirmationData?: {
    content1: string;
    content2: string;
    contentTitle: string;
    solutionName: string;
    warning: string;
  };
  handlingSummary?: { deposit: Record<string, unknown> };
  adaptedFirstAction?: { toCustomerComment?: string };
  loadingStatuses: {
    fileDownloadInProgress: boolean;
  };
}

const ActiveStatusTemplate: FunctionComponent<
  ActiveStatusTemplateContainerProps
> = ({
  fileId,
  confirmationClaimGroupValue,
  fileSummary,
  claimConfirmationData,
  handlingSummary,
  adaptedFirstAction,
  loadingStatuses,
}) => {
  const {
    content1,
    content2,
    contentTitle,
    deposit,
    homePickupInfos,
    rmaSupplier,
    storeInfo,
    storeActor,
    solutionName,
    trackingNumbers,
    claimId,
    partnerTransportDocumentUrl,
  } = useActiveStatusTemplateContainer({
    confirmationClaimGroupValue,
    fileSummary,
    claimConfirmationData,
    handlingSummary,
  });
  const isTwoColumnsLayout = !!content2;

  if (!fileId) {
    return <></>;
  }

  return (
    <>
      <ActionLabel action={adaptedFirstAction} index={0} />

      <CustomerCommentDisplay action={adaptedFirstAction} />

      {contentTitle && contentTitle !== NOT_TRANSLATED_LABEL && (
        <Heading level={2} dataTestId="followUpTitle">
          {contentTitle}
        </Heading>
      )}
      <CardSpacer>
        <Row>
          <Col
            xs={12}
            md={isTwoColumnsLayout ? 6 : 12}
            data-testid="followUpMessage"
          >
            <ActiveStatusTemplateContent
              content={content1}
              depositTrackingInfo={maxBy(
                filter(trackingNumbers, { handlingDirection: 'FromOwner' }),
                'wave',
              )}
              isLoading={loadingStatuses.fileDownloadInProgress}
              solutionName={solutionName}
              storeAddress={deposit}
              homePickupInfos={homePickupInfos}
              rmaSupplier={rmaSupplier}
              storeInfo={storeInfo}
              storeActor={storeActor}
              fileId={fileId}
              claimId={claimId}
              partnerTransportDocumentUrl={partnerTransportDocumentUrl}
            />
          </Col>

          {!!content2 && (
            <Col xs={12} md={6}>
              <ActiveStatusTemplateContent
                content={content2}
                depositTrackingInfo={maxBy(
                  filter(trackingNumbers, {
                    handlingDirection: 'FromOwner',
                  }),
                  'wave',
                )}
                isLoading={loadingStatuses.fileDownloadInProgress}
                solutionName={solutionName}
                storeAddress={deposit}
                storeActor={storeActor}
                homePickupInfos={homePickupInfos}
                fileId={fileId}
                claimId={claimId}
                partnerTransportDocumentUrl={partnerTransportDocumentUrl}
              />
            </Col>
          )}
        </Row>
      </CardSpacer>
    </>
  );
};

ActiveStatusTemplate.displayName = 'ActiveStatusTemplateContainer';

export default ActiveStatusTemplate;
