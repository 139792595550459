import React, { FC, PropsWithChildren, Suspense } from 'react';
import { useMedia, useWindowSize } from 'react-use';

import { BUTTON_TYPES, media } from '@savgroup-front-common/constants';

import { BaseLoader } from '../../molecules/BaseLoader';
import { CrossIcon } from '../../protons/icons';

import { useSwipeableDrawer } from './SwipeableDrawer.hooks';
import { $Backdrop, $CloseButton, $Sheet } from './SwipeableDrawer.styles';

export enum DIRECTION {
  TOP = 'top',
  LEFT = 'left',
}

export enum DRAWER_SIZE {
  TINY = 350,
  SMALL = 600,
  MEDIUM = 720,
  LARGE = 1120,
  FULL_SCREEN = 'FULL_SCREEN',
}

interface SwipeableDrawerProps {
  isOpen: boolean;
  hasBackdrop?: boolean;
  direction?: DIRECTION;
  size?: DRAWER_SIZE;
  onClose: () => void;
  hasPadding?: boolean;
  hasScroll?: boolean;
  hasBackdropScroll?: boolean;
  dataTestId?: string;
  hasCloseButton?: boolean;
}

const SwipeableDrawer: FC<PropsWithChildren<SwipeableDrawerProps>> = ({
  children,
  isOpen,
  direction = DIRECTION.LEFT,
  size = DRAWER_SIZE.LARGE,
  hasBackdrop = true,
  onClose,
  hasPadding = true,
  hasScroll = true,
  hasBackdropScroll = true,
  dataTestId,
  hasCloseButton = true,
}) => {
  const isMobileView = useMedia(media.maxWidth.xs);
  const { height: windowHeight } = useWindowSize();

  const currentSize = isMobileView ? DRAWER_SIZE.FULL_SCREEN : size;
  const currentDirection = isMobileView ? DIRECTION.TOP : direction;

  const { display, height, y, x } = useSwipeableDrawer({
    isOpen,
    onClose,
    hasBackdropScroll,
    direction: currentDirection,
    size: currentSize,
  });

  return (
    <>
      {isOpen && hasBackdrop && <$Backdrop onClick={onClose} $opacity={0.5} />}
      <$Sheet
        $isMobileView={isMobileView}
        $windowHeight={windowHeight}
        direction={currentDirection}
        size={currentSize}
        style={
          currentDirection === DIRECTION.TOP
            ? { display, bottom: `calc(-100vh + ${height - 100}px)`, y }
            : {
                display,
                right: 0,
                x,
              }
        }
        $hasPadding={hasPadding}
        $hasScroll={isMobileView ? true : hasScroll}
        data-testid={dataTestId}
      >
        {isOpen && (
          <>
            {hasCloseButton && (
              <$CloseButton
                naked
                type={BUTTON_TYPES.BUTTON}
                onClick={onClose}
                icon={<CrossIcon />}
                data-testid="modalClose"
              />
            )}
            <Suspense fallback={<BaseLoader />}>{children}</Suspense>
          </>
        )}
      </$Sheet>
    </>
  );
};

SwipeableDrawer.displayName = 'SwipeableDrawer';

export default SwipeableDrawer;
