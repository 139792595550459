import { createRequest } from '../../services/request';

export { LOAD_STORE_ACTOR_BY_CLAIM_ID } from './actionTypes/storeActor';

export const DELETE_CLAIM_BY_ID = createRequest('CLAIMS/DELETE_CLAIM_BY_ID');
export const LOAD_CLAIM_BY_ID = createRequest('CLAIMS/LOAD_CLAIM_BY_ID');
export const LOAD_ISSUES_OPTIONS_BY_CLAIM = createRequest(
  'CLAIMS/LOAD_ISSUES_OPTIONS_BY_CLAIM',
);
export const LOAD_REASONS_OPTIONS_BY_CLAIM = createRequest(
  'CLAIMS/LOAD_REASONS_OPTIONS_BY_CLAIM',
);
export const LOAD_SOLUTIONS_OPTIONS_BY_CLAIM = createRequest(
  'CLAIMS/LOAD_SOLUTIONS_OPTIONS_BY_CLAIM',
);
export const LOAD_CARRIERS_OPTIONS_BY_CLAIM = createRequest(
  'CLAIMS/LOAD_CARRIERS_OPTIONS_BY_CLAIM',
);

export const LOAD_PICKUP_POINTS_OPTIONS_BY_CARRIER = createRequest(
  'CLAIMS/LOAD_PICKUP_POINTS_OPTIONS_BY_CARRIER',
);
export const LOAD_CONFIRMATION_INFO_BY_CLAIM = createRequest(
  'CLAIMS/LOAD_CONFIRMATION_INFO_BY_CLAIM',
);
export const LOAD_FULL_CLAIM_BY_ID = createRequest(
  'CLAIMS/LOAD_FULL_CLAIM_BY_ID',
);
export const LOAD_TRANSLATION = createRequest('CLAIMS/LOAD_TRANSLATION');

export const SELECT_ISSUE_FOR_CLAIM = createRequest(
  'CLAIMS/SELECT_ISSUE_FOR_CLAIM',
);
export const SELECT_REASON_FOR_CLAIM = createRequest(
  'CLAIMS/SELECT_REASON_FOR_CLAIM',
);
export const SELECT_SOLUTION_FOR_CLAIM = createRequest(
  'CLAIMS/SELECT_SOLUTION_FOR_CLAIM',
);
export * from './actionCreators/claimDeadEnd';

export const SET_DOCUMENT_FOR_CLAIM_GROUP = createRequest(
  'CLAIMS/SET_DOCUMENT_FOR_CLAIM_GROUP',
);
export const DELETE_DOCUMENT_FOR_CLAIM_GROUP = createRequest(
  'CLAIMS/DELETE_DOCUMENT_FOR_CLAIM_GROUP',
);

export const CREATE_CLAIM_FOR_OWNER = createRequest(
  'CLAIMS/CREATE_CLAIM_FOR_OWNER',
);

export const DELETE_CLAIM_GROUP_BY_ID = createRequest(
  'CLAIMS/DELETE_CLAIM_GROUP_BY_ID',
);
export const LOAD_CLAIM_GROUP_BY_ID = createRequest(
  'CLAIMS/LOAD_CLAIM_GROUP_BY_ID',
);
export const LOAD_CLAIM_PRODUCT = createRequest('CLAIMS/LOAD_CLAIM_PRODUCT');

export const GET_REASON_COMMENT = createRequest('CLAIMS/GET_REASON_COMMENT');

export const SET_REASON_ADDITIONAL_INFORMATION = createRequest(
  'CLAIMS/SET_REASON_ADDITIONAL_INFORMATION',
);
export const SET_REASON_ADDITIONAL_INFORMATION_FILE = createRequest(
  'CLAIMS/SET_REASON_ADDITIONAL_INFORMATION_FILE',
);
export const DELETE_REASON_ADDITIONAL_INFORMATION_FILE = createRequest(
  'CLAIMS/DELETE_REASON_ADDITIONAL_INFORMATION_FILE',
);

export const SET_REASON_COMMENT_FOR_CLAIM_GROUP = createRequest(
  'CLAIMS/SET_REASON_COMMENT_FOR_CLAIM_GROUP',
);

export const LOAD_HANDLING_BY_CLAIM_GROUP = createRequest(
  'CLAIMS/LOAD_HANDLING_BY_CLAIM_GROUP',
);
export const SELECT_HANDLING_FOR_CLAIM_GROUP = createRequest(
  'CLAIMS/SELECT_HANDLING_FOR_CLAIM_GROUP',
);
export const LOAD_CARRIERS_OPTIONS_BY_CLAIM_GROUP = createRequest(
  'CLAIMS/LOAD_CARRIERS_OPTIONS_BY_CLAIM_GROUP',
);
export const LOAD_CARRIER_SUMMARY_BY_CLAIM_GROUP = createRequest(
  'CLAIMS/LOAD_CARRIER_SUMMARY_BY_CLAIM_GROUP',
);

export const CONFIRM_CLAIM_GROUP = createRequest('CLAIMS/CONFIRM_CLAIM_GROUP');
export const LOAD_CONFIRMATION_INFO_BY_CLAIM_GROUP = createRequest(
  'CLAIMS/LOAD_CONFIRMATION_INFO_BY_CLAIM_GROUP',
);

export const CREATE_CLAIM_GROUP_WITH_ISSUE_FOR_OWNER = createRequest(
  'CLAIMS/CREATE_CLAIM_GROUP_WITH_ISSUE_FOR_OWNER',
);
export const LOAD_ISSUES_OPTIONS_BY_ORDER = createRequest(
  'CLAIMS/LOAD_ISSUES_OPTIONS_BY_ORDER',
);
export const LOAD_ISSUES_OPTIONS_FROM_ALL_ORDERS = createRequest(
  'CLAIMS/LOAD_ISSUES_OPTIONS_FROM_ALL_ORDERS',
);

export const GET_SELLER_INFO = createRequest('CLAIMS/GET_SELLER_INFO');
export const CLEAR_CLAIM_CONFIRMATION_ERRORS =
  'CLAIMS/CLEAR_CLAIM_CONFIRMATION_ERRORS';

export const GET_RMA_SUPPLIER_INFOS = createRequest(
  'CLAIMS/GET_RMA_SUPPLIER_INFOS',
);

export const GET_ORDER_BY_CLAIM_GROUP = createRequest(
  'CLAIMS/GET_ORDER_BY_CLAIM_GROUP',
);
