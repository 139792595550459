import { fromJS } from 'immutable';
import get from 'lodash/get';

import * as ActionTypes from '../actionTypes';

function onloadOwnerProductFilesLoading(state, { meta }) {
  const ownerProductId = get(meta, 'ownerProductId');

  return state.update(
    ownerProductId,
    fromJS({ isLoaded: false, value: undefined, hasErrors: false }),
    (oldValue) => oldValue.set('hasErrors', true),
  );
}

function onloadOwnerProductFilesErrored(state, { meta }) {
  const ownerProductId = get(meta, 'ownerProductId');

  return state.update(ownerProductId, (oldValue) =>
    oldValue.set('hasErrors', true),
  );
}

function onloadOwnerProductFilesLoaded(state, { payload, meta }) {
  const ownerProductId = get(meta, 'ownerProductId');
  const value = get(payload, 'value');

  return state.update(ownerProductId, (oldValue) =>
    oldValue.set('isLoaded', true).set('value', value),
  );
}

export default function reducer(state = fromJS({}), action) {
  switch (action.type) {
    case ActionTypes.LOAD_OWNER_PRODUCT_FILES.STARTED: {
      return onloadOwnerProductFilesLoading(state, action);
    }

    case ActionTypes.LOAD_OWNER_PRODUCT_FILES.SUCCEEDED: {
      return onloadOwnerProductFilesLoaded(state, action);
    }

    case ActionTypes.LOAD_OWNER_PRODUCT_FILES.ERRORED: {
      return onloadOwnerProductFilesErrored(state, action);
    }

    default: {
      return state;
    }
  }
}
