import { datadogLogs } from '@datadog/browser-logs';

import { SEVERITY_LEVEL } from '@savgroup-front-common/constants/src/shared';

export type Exception = string | Error | unknown;
export interface LogException {
  error: Exception;
  severityLevel?: number;
}
export type Context =
  | Record<string, string | Record<string, unknown> | undefined | unknown>
  | undefined;

export function logException(
  { error, severityLevel = SEVERITY_LEVEL.ERROR }: LogException,
  context: Context = {},
): void {
  const serializedError =
    typeof error === 'string' ? error : JSON.stringify(error, null, 4);

  const exception = error instanceof Error ? error : new Error(serializedError);

  switch (severityLevel) {
    case SEVERITY_LEVEL.VERBOSE: {
      // eslint-disable-next-line no-console
      console.log(error, context);
      datadogLogs.logger.log(exception.message || serializedError, context);

      break;
    }
    case SEVERITY_LEVEL.WARNING: {
      // eslint-disable-next-line no-console
      console.warn(error, context);
      datadogLogs.logger.warn(exception.message || serializedError, context);

      break;
    }

    case SEVERITY_LEVEL.ERROR:
    default: {
      // eslint-disable-next-line no-console
      console.error(error, context);
      datadogLogs.logger.error(exception.message || serializedError, context);

      break;
    }
  }
}

export function logError(error: Exception, context: Context = {}): void {
  logException({ error, severityLevel: SEVERITY_LEVEL.ERROR }, context);
}

export function logCritical(error: Exception, context: Context = {}): void {
  logException({ error, severityLevel: SEVERITY_LEVEL.CRITICAL }, context);
}

export function logVerbose(error: Exception, context: Context = {}): void {
  logException({ error, severityLevel: SEVERITY_LEVEL.VERBOSE }, context);
}
