import { yupResolver } from '@hookform/resolvers/yup';
import { useElements, useStripe } from '@stripe/react-stripe-js';
import { useState } from 'react';
import { useForm } from 'react-hook-form';

import { REVALIDATE_MODES } from '@savgroup-front-common/constants';
import { useToasts } from '@savgroup-front-common/core/src/molecules/NotificationsProvider';
import { PaymentService } from 'myaccount/api';

import checkoutFormSchema from './CheckoutForm.schema';
import { CheckoutFormValues } from './CheckoutForm.types';
import messages from './messages';

interface UseCheckoutFormArgs {
  onAllSubmit: () => void;
  invoiceId: string;
}

const useCheckoutForm = ({ onAllSubmit, invoiceId }: UseCheckoutFormArgs) => {
  const [paymentLoading, setPaymentLoading] = useState(false);
  const { pushErrors, pushError } = useToasts({ extendedMessages: messages });
  const stripe = useStripe();
  const elements = useElements();

  const formContext = useForm<CheckoutFormValues>({
    resolver: yupResolver(checkoutFormSchema),
    mode: REVALIDATE_MODES.ON_CHANGE,
    defaultValues: {
      name: '',
    },
  });

  const { handleSubmit } = formContext;

  const onSubmit = handleSubmit(async ({ name }) => {
    setPaymentLoading(true);

    if (!stripe || !elements) {
      return undefined;
    }

    const responseStripe = await stripe.confirmPayment({
      elements,
      redirect: 'if_required' as any,
      confirmParams: {
        payment_method_data: {
          billing_details: {
            name,
          },
        },
      },
    });

    if (responseStripe.error) {
      pushError({
        name: `paymentFailure.${responseStripe.error.code}.${responseStripe.error.decline_code}`,
        message: '',
      });

      setPaymentLoading(false);

      return undefined;
    }

    const responseConfirm = await PaymentService.confirmPaymentIntent({
      invoiceId,
    });

    if (responseConfirm.failure) {
      pushErrors(responseConfirm.errors);

      setPaymentLoading(false);

      return undefined;
    }

    return Promise.all([responseStripe, responseConfirm])
      .then(() => setPaymentLoading(false))
      .then(() => {
        onAllSubmit();
      });
  });

  return {
    formContext,
    onSubmit,
    paymentLoading,
  };
};

export default useCheckoutForm;
