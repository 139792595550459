import { CIVILITY } from '@savgroup-front-common/constants';

import { AddressInfoDto } from './AddressInfo';
import { FileId } from './File';
import {
  DeliveryLocationType,
  RequestLineStatus,
  RequestReliability,
} from './SparePartRequest';
import { STOCK_ITEM_STATUS } from './Stock';

export type RequestLineId = string;

export enum SPARE_PART_HANDLING_TYPES {
  CUSTOMER = 'Customer',
  STORE = 'Store',
  REPAIR_CENTER = 'RepairCenter',
  SPECIFIC = 'Specific',
}

export interface SparePartRequestLine {
  requestLineId: string;
  handlingType: SPARE_PART_HANDLING_TYPES;
  requestLineStatus: RequestLineStatus;
  deliveryAddressName: string;
  requestLineLastModificationTimeUtc: string;
  sparePartReference: string;
  supplierName: string;
  requesterName: string;
  fileId: string;
}

export interface SparePartRequestLineDetails {
  requestLineId: RequestLineId;
  supplierName: string;
  excludingTaxPurchasePriceEur?: number;
  externalOrderId?: string;
  requestLineCreationTimeUtc: string;
  requestLineLastModificationTimeUtc: string;
  requesterFirstName: string;
  requesterLastName: string;
  handlingType: SPARE_PART_HANDLING_TYPES;
  deliveryAddress: AddressInfoDto;
  fileReference?: string;
  fileId?: string;
  sparePartId?: string;
  stockItemId?: string;
  sparePartReference: string;
  requestLineStatus: RequestLineStatus;
  supplierId: string;
  requesterId: string | null;
}
export interface FileSparePartRequestLine {
  fileId: FileId;
  requestCreationTimeUtc: string;
  deliveryAddress: AddressInfoDto;
  handlingType: DeliveryLocationType;
  requesterFirstName: string;
  requesterLastName: string;
  supplierName: string;
  requestLineId: RequestLineId;
  requestLineStatus: RequestLineStatus;
  sparePartReference: string;
  lastModificationTimeUtc: string;
  stockItemStatus?: STOCK_ITEM_STATUS;
  isNewModel?: boolean;
  deliveryLocationName?: string;
  lineReliability?: RequestReliability;
  manufacturerName?: string;
  manufacturerReference?: string;
  supplierReference?: string;
  label?: string;
}

export interface SparePartRequestDeliveryAddress {
  civility: CIVILITY;
  firstname?: string;
  companyName?: string;
  lastname?: string;
  address: string;
  additionalAddress?: string;
  postalCode: string;
  city: string;
  countryCode: string;
  phone?: string;
}
