import {
  SparePartQuotationLine,
  SparePartSearchSummary,
} from '@savgroup-front-common/types';

import {
  UpdateQuotationOptionalLineUnavailableSparePart,
  UpdateQuotationSparePartSelectionCommandArgs,
} from '../../../api/RepairerService/updateQuotationSparePartSelectionCommand';
import { sortBy } from '../../../helpers';

import {
  QuotationPendingCustomerValidationForm,
  QuotationPendingCustomerValidationNewModel,
} from './QuotationPendingCustomerValidation.types';

export const adaptAlternativeSpareParts = ({
  quotation,
}: {
  quotation: QuotationPendingCustomerValidationNewModel;
}) => {
  const alternativeSpareParts = (quotation.sparePartLines || []).reduce<
    SparePartSearchSummary[]
  >((acc, line) => {
    return [...acc, ...(line.alternativeSpareParts || [])];
  }, []);

  return alternativeSpareParts.reduce<
    Record<string, Record<string, SparePartSearchSummary>>
  >((acc, alternativeSparePart) => {
    return {
      ...acc,
      [alternativeSparePart.sparePartId]: {
        ...(acc[alternativeSparePart.sparePartId] || {}),
        [alternativeSparePart.sparePartSupplierAssociationId]:
          alternativeSparePart,
      },
    };
  }, {});
};

export const prepareUpdateQuotationSparePartSelectionCommandPayload = ({
  quotationId,
  values,
  sparePartQuotationLines,
  alternativeSpareParts,
}: {
  quotationId: string;
  values: QuotationPendingCustomerValidationForm;
  sparePartQuotationLines?: SparePartQuotationLine[];
  alternativeSpareParts: Record<string, Record<string, SparePartSearchSummary>>;
}): UpdateQuotationSparePartSelectionCommandArgs => {
  return {
    quotationId,
    quotationSpareParts: (sparePartQuotationLines || [])
      .filter((sparePartQuotationLine) => {
        return values.sparePartLines[sparePartQuotationLine.id];
      })
      .map((sparePartQuotationLine) => {
        const concernedAlternativeSpareParts = Object.values(
          alternativeSpareParts[sparePartQuotationLine.sparePartId],
        );

        const sparePartQuotationFormLine =
          values.sparePartLines[sparePartQuotationLine.id];

        const isOptionalLineUnselected =
          !sparePartQuotationFormLine.condition &&
          !sparePartQuotationFormLine.isSelectedForRepair &&
          !sparePartQuotationLine.isRequiredForRepair;

        if (isOptionalLineUnselected) {
          return {
            sparePartId: sparePartQuotationLine.sparePartId,
            id: sparePartQuotationLine.id,
            isRequiredForRepair: sparePartQuotationLine.isRequiredForRepair,
            isSelectedForRepair: sparePartQuotationFormLine.isSelectedForRepair,
            recipient: {
              recipientType: sparePartQuotationLine.recipient?.data,
            },
            supplierId: sparePartQuotationLine.sparePartSupplierId,
            sparePart: {
              requestLineId: sparePartQuotationLine.requestLineId,
            },
          } as UpdateQuotationOptionalLineUnavailableSparePart;
        }

        const sparePart = concernedAlternativeSpareParts
          .sort(
            sortBy({
              ascending: true,
              fieldName: 'recommendedSalePriceIncludingTaxes.amount',
            }),
          )
          .find((part) => {
            return (
              part.sparePartId === sparePartQuotationLine.sparePartId &&
              part.condition === sparePartQuotationFormLine.condition
            );
          });

        if (
          !sparePart ||
          !sparePartQuotationLine.requestLineId ||
          sparePartQuotationLine.isRequiredForRepair === undefined ||
          !sparePartQuotationLine.recipient ||
          !sparePartQuotationFormLine.condition
        ) {
          throw new Error('Invalid state.');
        }

        return {
          id: sparePartQuotationLine.id,
          sparePartId: sparePartQuotationLine.sparePartId,
          isRequiredForRepair: sparePartQuotationLine.isRequiredForRepair,
          isSelectedForRepair: sparePartQuotationFormLine.isSelectedForRepair,
          recipient: {
            recipientType: sparePartQuotationLine.recipient.data,
          },
          supplierId: sparePart.sparePartSupplierId,
          sparePart: {
            sparePartSupplierAssociationId:
              sparePart.sparePartSupplierAssociationId,
            supplierReference: sparePart.supplierPartReference,
            supplierId: sparePart.sparePartSupplierId,
            preTaxUnitBuyingPrice: sparePart.pretaxPurchasePrice,
            preTaxUnitPrice: sparePart.pretaxPurchasePrice,
            preTaxAmount: sparePart.pretaxPurchasePrice,
            amountIncludedTax: sparePart.recommendedSalePriceIncludingTaxes,
            quantity: 1,
            requestLineId: sparePartQuotationLine.requestLineId,
            condition: sparePartQuotationFormLine.condition,
            supplierName: sparePartQuotationLine.supplierName,
            manufacturerName: sparePartQuotationLine.manufacturerLabel,
            manufacturerReference: sparePartQuotationLine.manufacturerReference,
            minDeliveryDays: sparePart.minDeliveryDays,
            maxDeliveryDays: sparePart.maxDeliveryDays,
            stockName: sparePart.stockName,
          },
        };
      }),
  };
};
