/* eslint-disable react/no-array-index-key */
import React, { FC } from 'react';
import { FormattedNumber } from 'react-intl';
import { Col, Row } from 'react-styled-flexboxgrid';

import { H3 } from '@savgroup-front-common/core/src/atoms/headings';
import { InjectSvg } from '@savgroup-front-common/core/src/atoms/images';
import { SafeFormattedMessageWithoutSpread } from '@savgroup-front-common/core/src/formatters';
import { getSolutionIconUrl } from '@savgroup-front-common/core/src/helpers';
import {
  Document,
  OrderProductSummary,
  SUPPORTED_COMPONENTS,
} from '@savgroup-front-common/types';

import OrderProductInfo from '../../OrderProductInfo/OrderProductInfo';

import useGetDocuments from './hooks/useGetDocuments';
import messages from './messages';
import {
  $LinkButton,
  Column,
  Divider,
  DocumentsMessageColumn,
  ImageContainer,
  ReasonAndSolutionRow,
  ReasonColumn,
  SolutionColumn,
  SolutionContainer,
  SolutionPrice,
  SolutionText,
  SolutionTitle,
  TitleH2,
  TitleRow,
} from './ProductIRSSummary.styles';
import ProductIRSSummaryDocuments from './ProductIRSSummaryDocuments';

interface ProductIRSSummaryProps {
  product: OrderProductSummary;
  solution: string;
  reason: string;
  comment: string;
  documents?: Document[];
  price: number;
  priceUnit: string;
  goToEditionPage?: () => void;
  solutionTypeKey?: string;
  claimId: string;
}

const ProductIRSSummary: FC<ProductIRSSummaryProps> = ({
  product,
  solution,
  reason,
  comment,
  documents,
  price,
  priceUnit,
  goToEditionPage,
  solutionTypeKey,
  claimId,
}) => {
  const { fallbackUrl, iconUrl } = getSolutionIconUrl(solutionTypeKey);
  const { allDocuments, handlePrintDocument } = useGetDocuments({
    claimId,
    documents,
  });

  return (
    <div>
      <OrderProductInfo minimal {...product} />
      <Divider />
      <Row middle="xs">
        <Col>
          <TitleH2>
            <SafeFormattedMessageWithoutSpread message={messages.title} />
          </TitleH2>
        </Col>
        {goToEditionPage && (
          <Col>
            <$LinkButton onClick={goToEditionPage}>
              <SafeFormattedMessageWithoutSpread
                message={messages.editReasonSolution}
              />
            </$LinkButton>
          </Col>
        )}
      </Row>
      <ReasonAndSolutionRow>
        <ReasonColumn>
          <Column>
            <TitleRow className="reasonInformation">
              <SafeFormattedMessageWithoutSpread message={messages.reason} />
            </TitleRow>
            <H3>{reason}</H3>
          </Column>
          <Column>
            <TitleRow>
              <SafeFormattedMessageWithoutSpread message={messages.comment} />
            </TitleRow>
            <H3>
              {comment || (
                <SafeFormattedMessageWithoutSpread
                  message={messages.noComment}
                />
              )}
            </H3>
          </Column>
        </ReasonColumn>
        <SolutionColumn>
          <TitleRow className="solutionInformation">
            <SafeFormattedMessageWithoutSpread message={messages.solution} />
          </TitleRow>
          <SolutionContainer>
            <ImageContainer>
              <InjectSvg
                iconUrl={iconUrl}
                iconUrlFallback={fallbackUrl}
                componentThemeName={SUPPORTED_COMPONENTS.IRSH_ICONS}
              />
            </ImageContainer>
            <SolutionText>
              <SolutionTitle>{solution}</SolutionTitle>
              <SolutionPrice>
                {price > 0 && (
                  <FormattedNumber value={price} format={priceUnit} />
                )}
              </SolutionPrice>
            </SolutionText>
          </SolutionContainer>
        </SolutionColumn>
      </ReasonAndSolutionRow>
      <TitleRow>
        <SafeFormattedMessageWithoutSpread message={messages.documents} />
        {allDocuments.map((document, i) => (
          <ProductIRSSummaryDocuments
            document={document}
            key={String(i)}
            onPrintDocument={handlePrintDocument}
          />
        ))}
      </TitleRow>
      <DocumentsMessageColumn>
        {allDocuments.length === 0 && (
          <SafeFormattedMessageWithoutSpread message={messages.noDocuments} />
        )}
      </DocumentsMessageColumn>
    </div>
  );
};

ProductIRSSummary.displayName = 'ProductIRSSummary';

export default ProductIRSSummary;
