import { createAction } from '@reduxjs/toolkit';

import * as actionTypes from './actionTypes';

export {
  loadStoreActorByClaimId,
  setStoreActor,
} from './actionCreators/storeActor';

export const deleteClaimById = createAction<string>(
  actionTypes.DELETE_CLAIM_BY_ID.BASE,
);

export const loadClaimById = createAction<string>(
  actionTypes.LOAD_CLAIM_BY_ID.BASE,
);

export const loadIssuesFromAllOrder = createAction(
  actionTypes.LOAD_ISSUES_OPTIONS_FROM_ALL_ORDERS.BASE,
);
export const loadIssuesFromAllOrderStarted = createAction(
  actionTypes.LOAD_ISSUES_OPTIONS_FROM_ALL_ORDERS.STARTED,
);
export const loadIssuesFromAllOrderSucceeded = createAction(
  actionTypes.LOAD_ISSUES_OPTIONS_FROM_ALL_ORDERS.SUCCEEDED,
);

export const loadIssuesByOrder = createAction<{
  orderId: string;
  ignoreDateRulesChecking: boolean;
}>(actionTypes.LOAD_ISSUES_OPTIONS_BY_ORDER.BASE);
export const loadIssuesByClaim = createAction<string>(
  actionTypes.LOAD_ISSUES_OPTIONS_BY_CLAIM.BASE,
);
export const loadReasonsByClaim = createAction<string>(
  actionTypes.LOAD_REASONS_OPTIONS_BY_CLAIM.BASE,
);
export const loadSolutionsByClaim = createAction<string>(
  actionTypes.LOAD_SOLUTIONS_OPTIONS_BY_CLAIM.BASE,
);

export const loadCarriersByClaim = createAction<{
  claimId: string;
  handlingMode: string;
}>(actionTypes.LOAD_CARRIERS_OPTIONS_BY_CLAIM.BASE);

export const loadPickupPointsForCarrier = createAction<{
  sellerId: string;
  claimId: string;
  address: unknown;
  countryCode?: string;
  maxDistance: number;
  carrierCompany: string;
  productType: string;
}>(actionTypes.LOAD_PICKUP_POINTS_OPTIONS_BY_CARRIER.BASE);
export const loadConfirmationInfoByClaim = createAction<string>(
  actionTypes.LOAD_CONFIRMATION_INFO_BY_CLAIM.BASE,
);
export const loadFullClaimById = createAction<{
  claimId: string;
  forceRefresh?: boolean;
}>(actionTypes.LOAD_FULL_CLAIM_BY_ID.BASE);
export const loadTranslation = createAction<{ warrantyTypeId: string }>(
  actionTypes.LOAD_TRANSLATION.BASE,
);

export const selectIssueForClaim = createAction<{
  claimId: string;
  issueId: string;
}>(actionTypes.SELECT_ISSUE_FOR_CLAIM.BASE);
export const selectReasonForClaim = createAction<{
  claimId: string;
  reasonId: string;
  reasonComment: string;
}>(actionTypes.SELECT_REASON_FOR_CLAIM.BASE);

export const selectSolutionForClaim = createAction<{
  claimId: string;
  solutionTypeId: string;
  solutionPrice: number;
}>(actionTypes.SELECT_SOLUTION_FOR_CLAIM.BASE);

export const setDocumentForClaimGroup = createAction(
  actionTypes.SET_DOCUMENT_FOR_CLAIM_GROUP.BASE,
);
export const setDocumentForClaimGroupSuccess = createAction(
  actionTypes.SET_DOCUMENT_FOR_CLAIM_GROUP.SUCCEEDED,
);

export const deleteDocumentForClaimGroup = createAction<{
  claimGroupId: string;
  documentId: string;
  claimId: string;
}>(actionTypes.DELETE_DOCUMENT_FOR_CLAIM_GROUP.BASE);

export const createClaimGroupWithIssueForOwner = createAction(
  actionTypes.CREATE_CLAIM_GROUP_WITH_ISSUE_FOR_OWNER.BASE,
);

export const deleteClaimGroupById = createAction<string>(
  actionTypes.DELETE_CLAIM_GROUP_BY_ID.BASE,
);
export const loadClaimGroupById = createAction<string>(
  actionTypes.LOAD_CLAIM_GROUP_BY_ID.BASE,
);

export const loadClaimProduct = createAction<string>(
  actionTypes.LOAD_CLAIM_PRODUCT.BASE,
);

export const getReasonComment = createAction<string>(
  actionTypes.GET_REASON_COMMENT.BASE,
);

export const setReasonCommentForClaimGroup = createAction(
  actionTypes.SET_REASON_COMMENT_FOR_CLAIM_GROUP.BASE,
);

export const setReasonAdditionalInformation = createAction(
  actionTypes.SET_REASON_ADDITIONAL_INFORMATION.BASE,
);

export const setReasonAdditionalInformationFile = createAction(
  actionTypes.SET_REASON_ADDITIONAL_INFORMATION_FILE.BASE,
);
export const deleteReasonAdditionalInformationFile = createAction(
  actionTypes.DELETE_REASON_ADDITIONAL_INFORMATION_FILE.BASE,
);

export const loadHandlingByClaimGroup = createAction<{ claimGroupId: string }>(
  actionTypes.LOAD_HANDLING_BY_CLAIM_GROUP.BASE,
);
export const selectHandlingForClaimGroup = createAction<{
  claimGroupId: string;
  handlingMode: string;
  pickupPointId: string;
  pickupPointAddress: {
    label: string;
    address: unknown;
    additionalAddress: unknown;
    postalCode: string;
    city: string;
    countryCode: string;
  } | null;
  carrierPriceId: {
    carrierSellerPriceId: string;
    carrierCustomerPriceId: string;
  };
  address: unknown;
}>(actionTypes.SELECT_HANDLING_FOR_CLAIM_GROUP.BASE);
export const loadCarriersByClaimGroup = createAction<{
  claimGroupId: string;
  handlingMode: string;
}>(actionTypes.LOAD_CARRIERS_OPTIONS_BY_CLAIM_GROUP.BASE);
export const loadCarrierSummaryByClaimGroup = createAction<{
  claimGroupId: string;
}>(actionTypes.LOAD_CARRIER_SUMMARY_BY_CLAIM_GROUP.BASE);

export const confirmClaimGroup = createAction(
  actionTypes.CONFIRM_CLAIM_GROUP.BASE,
);
export const loadConfirmationInfoByClaimGroup = createAction<string>(
  actionTypes.LOAD_CONFIRMATION_INFO_BY_CLAIM_GROUP.BASE,
);

export const getSellerInfo = createAction<{
  sellerId: string;
  sellerProductId: string;
}>(actionTypes.GET_SELLER_INFO.BASE);
export const clearClaimConfirmationErrors = createAction(
  actionTypes.CLEAR_CLAIM_CONFIRMATION_ERRORS,
);

export const getRmaSupplierInfos = createAction<string>(
  actionTypes.GET_RMA_SUPPLIER_INFOS.BASE,
);

export const getOrderByClaimGroup = createAction<string>(
  actionTypes.GET_ORDER_BY_CLAIM_GROUP.BASE,
);

export * from './actionCreators/claimDeadEnd';
