import { createContext } from 'react';

import {
  ActorSummary,
  FileSummary,
  Store,
  TechnicalIntervention,
} from '@savgroup-front-common/types';

import { LocalizedAdditionalInformation } from '../../api/Claim/getAdditionalInformationEntitiesSummaries';

interface ContentTemplateContextValues {
  fileId?: string;
  claimId?: string;
  fileReference?: string;
  orderReference?: string;
  actorSummary?: ActorSummary;
  solutionName?: string;
  storeAddress?: Record<string, any>;
  depositCarrierName?: any;
  isLoading?: boolean;
  homePickupInfos?: Record<string, any>[];
  rmaSupplier?: Record<string, any>;
  storeInfo?: Store;
  storeActor?: Store;
  getDocumentStatusByFileId?: (data: any) => any;
  getDocumentStatusByFileIdValue?: Record<string, string>;
  latestTechnicalIntervention?: TechnicalIntervention;
  customerFile?: FileSummary;
  partnerTransportDocumentUrl?: string;
  additionalInformationEntitiesMap?: Record<
    string,
    LocalizedAdditionalInformation
  >;
  pictures?: (icon: string) => JSX.Element;
  picturesGrey?: (icon: string) => JSX.Element;
}

export const ContentTemplateContext =
  createContext<ContentTemplateContextValues>({});
