import get from 'lodash/get';
import React, { FunctionComponent, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useTheme } from 'styled-components';

import { MDXContent } from '@savgroup-front-common/core/src/components/MDXContent/MDXContent';
import {
  ContentTemplateContext,
  customComponents,
} from '@savgroup-front-common/core/src/components/MDXCustomComponents';
import { MyAccountIcon } from '@savgroup-front-common/core/src/protons/IconsNewDesign/MyAccount.icon';
import { ActorsHooks } from '@savgroup-front-common/core/src/domains/actors';
import {
  ActionCreators as fileAttachmentsActionCreators,
  Selectors as fileAttachmentsSelectors,
} from '@savgroup-front-common/core/src/domains/attachments';
import useCommonTypedSelector from '@savgroup-front-common/core/src/domains/useCommonTypedSelector';
import {
  CustomerFileSummary,
  HandlingSpot,
  Store,
} from '@savgroup-front-common/types';
import { ICONS_TYPE } from '@savgroup-front-common/types/src/Icon';
import { useGetTechnicalInterventionQuery } from 'myaccount/view/app/hooks/useGetTechnicalInterventionQuery';

import { useGetAdditionalInformationEntities } from './hooks/useGetAdditionalInformationEntities';

interface ActiveStatusTemplateProps {
  fileId: string;
  claimId?: string;
  content: string;
  solutionName?: string;
  storeAddress?: HandlingSpot;
  isLoading: boolean;
  homePickupInfos?: Record<string, unknown>[];
  rmaSupplier?: Record<string, unknown>;
  depositTrackingInfo?: Record<string, unknown>;
  storeInfo?: Store;
  storeActor?: Store;
  partnerTransportDocumentUrl?: string;
  fileSummary: CustomerFileSummary;
}

const ActiveStatusTemplateContent: FunctionComponent<
  React.PropsWithChildren<ActiveStatusTemplateProps>
> = ({
  content,
  depositTrackingInfo,
  solutionName = '',
  storeAddress,
  isLoading,
  homePickupInfos = [],
  rmaSupplier,
  storeInfo,
  storeActor,
  fileId,
  claimId,
  partnerTransportDocumentUrl,
  fileSummary,
}) => {
  const dispatch = useDispatch();
  const theme = useTheme();

  const depositCarrierName =
    get(depositTrackingInfo, 'unknownCarrierName') ||
    get(depositTrackingInfo, 'externalCarrier') ||
    '-';
  const { technicalIntervention: latestTechnicalIntervention } =
    useGetTechnicalInterventionQuery({
      fileId,
    });

  const documentStatusByFileIdValue = useCommonTypedSelector((state) =>
    fileAttachmentsSelectors.getDocumentsStatusByFileIdValue(state, fileId),
  );
  const getDocumentStatusByFileId = useCallback(
    (data: { fileId: string; type: string }) => {
      dispatch(fileAttachmentsActionCreators.getDocumentsStatusByFileId(data));
    },
    [dispatch],
  );

  const { actorSummary } = ActorsHooks.useGetActorSummary({
    actorId: fileSummary.actorId,
    actorType: fileSummary.actorType,
    ownerId: fileSummary.ownerId,
    sellerId: fileSummary.sellerId,
  });

  const {
    isLoading: getAdditionalInformationEntitiesIsLoading,
    additionalInformationEntitiesMap,
  } = useGetAdditionalInformationEntities({
    fileSummary,
  });

  const addressStore = storeAddress?.pickupPointAddress
    ? storeAddress.pickupPointAddress
    : storeAddress?.address;

  return (
    <ContentTemplateContext.Provider
      value={{
        solutionName,
        storeAddress: addressStore as Record<string, unknown> | undefined,
        depositCarrierName,
        isLoading: isLoading || getAdditionalInformationEntitiesIsLoading,
        homePickupInfos,
        rmaSupplier,
        storeInfo,
        storeActor,
        fileId,
        getDocumentStatusByFileId,
        getDocumentStatusByFileIdValue: documentStatusByFileIdValue,
        claimId,
        latestTechnicalIntervention,
        orderReference: fileSummary?.orderReference,
        fileReference: fileSummary?.fileReference,
        customerFile: fileSummary,
        actorSummary,
        partnerTransportDocumentUrl,
        additionalInformationEntitiesMap,
        // eslint-disable-next-line react/display-name
        pictures: (icon?: string) => (
          <MyAccountIcon
            icon={icon as ICONS_TYPE}
            color={theme.colors.primary}
          />
        ),
        // eslint-disable-next-line react/display-name
        picturesGrey: (icon?: string) => (
          <MyAccountIcon icon={icon as ICONS_TYPE} color="#8A94A6" />
        ),
      }}
    >
      <MDXContent customComponents={customComponents}>{content}</MDXContent>
    </ContentTemplateContext.Provider>
  );
};

ActiveStatusTemplateContent.displayName = 'ActiveStatusTemplateContent';

export default ActiveStatusTemplateContent;
